import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardContent, CardActions, Button, Box, makeStyles, Typography } from "@material-ui/core";
import { useTranslate } from "react-polyglot";
import PrimaryDarkTypography from "../../../SharedComponents/PrimaryDarkTypography.jsx";
import { useNews } from "../../../Providers/Data/NewsProvider.jsx";
import useExtractRouteParamInt from "../../../hooks/useExtractRouteParamInt.js";
import { sanitizedData } from "../../../Utils/misc.js";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    display: "flex",
    flexDirection: "column",
    minHeight: 350,
    [theme.breakpoints.up(520)]: {
      flexDirection: "row",
    },
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflowY: "auto",
  },
  content: {
    flex: "1 0 auto",
    padding: "18px 24px",
    maxHeight: "70vh",
    overflowY: "auto",
  },
  contentBox: {
    "& p *": {
      overflowWrap: "anywhere",
    },
    overflowWrap: "anywhere",
    paddingBottom: "24px",
  },
  mediaBox: {
    width: "100%",
    height: "auto",
    [theme.breakpoints.up(520)]: {
      width: "auto",
      maxWidth: "40%",
    },
  },
  media: {
    display: "block",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  action: {
    padding: "18px 24px",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

/** */
const NewsContent = () => {
  const classes = useStyles();
  const t = useTranslate();
  const { news, watchNews } = useNews();
  const history = useHistory();
  const newsId = useExtractRouteParamInt("/news/:newsId", "newsId");
  const [theNews, setNews] = useState(/** @type {?TheNews} */ null);

  // init content
  useEffect(() => {
    setNews(news.find((n) => n.id === newsId));
    watchNews(newsId);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsId, news]);

  if (!theNews) {
    return null;
  }

  /** */
  const cardClickHandler = () => {
    if (theNews.url) {
      window.open(theNews.url, "_blank").focus();
    } else if (theNews.deck) {
      history.push(`/learn/${theNews.deck.id}`);
    }
  };

  return (
    <Card className={classes.cardRoot}>
      <Box className={classes.mediaBox}>
        <img className={classes.media} src={theNews.image.url} />
      </Box>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <PrimaryDarkTypography variant="h6" gutterBottom>
            {theNews.name}
          </PrimaryDarkTypography>
          <Typography className={classes.contentBox} variant="body2">
            <span dangerouslySetInnerHTML={sanitizedData(theNews.content)} />
          </Typography>
        </CardContent>
        {(theNews.url || theNews.deck) && (
          <CardActions className={classes.action}>
            <Button onClick={cardClickHandler} variant="contained" color="primary">
              {t("go")}
            </Button>
          </CardActions>
        )}
      </div>
    </Card>
  );
};

export default NewsContent;
